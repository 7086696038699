<template>
  <div class="row">
<!--    <div class="col-lg-12">-->
<!--      <div class="row">-->
<!--        <div class="col-lg-8 col-sm-8 filters">-->
<!--          <div class="filters__search">-->
<!--            <div class="input-group input-group-sm">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-search"></i>-->
<!--              </span>-->
<!--              <input-->
<!--                id="search"-->
<!--                name="search"-->
<!--                :placeholder="$t('aps.search')"-->
<!--                class="form-control filters__input"-->
<!--                v-model="filters.query"-->
<!--                @input="disableStatMode()"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="filters__multiple mb-1">-->
<!--            <div class="input-group input-group-sm filters__sort mr-1">-->
<!--              <span class="input-group-addon filters__field-icon">-->
<!--                <i class="fa fa-sort-amount-desc icon-sort"></i>-->
<!--              </span>-->
<!--              <select class="form-control form-control-sm filters__field" v-model="sortByFilter">-->
<!--                <option :selected="true" value="Disconnected">{{ $t('monitor.sessionDisconnected') }}</option>-->
<!--                <option :selected="true" value="Connected">{{ $t('monitor.sessionConnected') }}</option>-->
<!--              </select>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location mr-1">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;              </span>&ndash;&gt;-->
<!--&lt;!&ndash;              <select&ndash;&gt;-->
<!--&lt;!&ndash;                class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.location"&ndash;&gt;-->
<!--&lt;!&ndash;                @input="disableStatModeAndRefreshSessions"&ndash;&gt;-->
<!--&lt;!&ndash;              >&ndash;&gt;-->
<!--&lt;!&ndash;                <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                  {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                </option>&ndash;&gt;-->
<!--&lt;!&ndash;              </select>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="  d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="disableStatModeAndRefreshSessions"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->

<!--            <div-->
<!--              class="col-lg-4  ml-1 input-group-for-location-select-in-monitoringSessions&#45;&#45;filters">-->


<!--                                                                   <span-->
<!--                                                                     class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--              <SelectComponentV2-->
<!--                v-if="showLocationFilter"-->
<!--                no-wrap-selected-option-->
<!--                class="select-location-in-monitoringSessions&#45;&#45;filters"-->
<!--                enable-max-option-width-->
<!--                small-size-->
<!--                show-delete-button-->
<!--                v-model="locationInFiltersData"-->
<!--                track-by="name"-->
<!--                option-id-name="id"-->
<!--                :no-options-found="$t('general.noResultForSearch')"-->
<!--                :async-function="requestLocations.bind(this)"-->
<!--                :placeholder="$t('portalStats.selectLocation')"-->
<!--                @select="selectLocationInFilters"-->
<!--              >-->
<!--                <template #dropdown-start>-->
<!--                  <div class="ml-h mt-1">-->
<!--                    <Switch-component-->
<!--                      @input="disableStatModeAndRefreshSessions"-->
<!--                      :label="$t('general.withChild')"-->
<!--                      v-model="filters.with_childs"-->
<!--                      class=""-->
<!--                    />-->
<!--                  </div>-->
<!--                </template>-->
<!--              </SelectComponentV2>-->


<!--            </div>-->



<!--          </div>-->
<!--          <div v-if="isFiltersActive" class="mb-1">-->
<!--              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--                <i class="fa fa-close"></i>-->
<!--                {{ $t('general.resetAllFilters') }}-->
<!--              </button>-->
<!--            </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12 col-sm-12 filters">
          <div class="row">
          <div class="col-lg-3 mb-h pr-0">

                      <div class="filters__search">
            <div class="input-group input-group-sm">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input
                id="search"
                name="search"
                :placeholder="$t('aps.search')"
                class="form-control filters__input"
                v-model="filters.query"
                @input="disableStatMode()"
              />
            </div>
          </div>

          </div>
          <div v-if="areLocationsVisible" class="col-lg-3 mb-h pr-0">
                        <div
              class="   input-group-for-location-select-in-monitoringSessions--filters">


                                                                   <span
                                                                     class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
              <SelectComponentV3ForUseInLocationSelectors

                v-if="showLocationFilter"
                no-wrap-selected-option
                class="select-location-in-monitoringSessions--filters"
                enable-max-option-width
                small-size
                :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                v-model="locationInFiltersData"
                track-by="name"
                option-id-name="id"
                :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                :no-options-found="$t('general.noResultForSearch')"
                :async-function="requestLocations.bind(this)"
                :placeholder="$t('portalStats.selectLocation')"
                @select="selectLocationInFilters"
              >
                <template #dropdown-start>
                  <div class="ml-h mt-1">
                    <Switch-component
                       v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                      @input="disableStatModeAndRefreshSessions"
                      :label="$t('general.withChild')"
                      v-model="filters.with_childs"
                      class=""
                    />
                  </div>
                </template>
              </SelectComponentV3ForUseInLocationSelectors>


            </div>
          </div>



          <div class="col-lg-3 mb-h pr-0">
                      <div class="filters__multiple ">
            <div class="input-group input-group-sm filters__sort mr-1">
              <span class="input-group-addon filters__field-icon">
                <i class="fa fa-sort-amount-desc icon-sort"></i>
              </span>
              <select class="form-control form-control-sm filters__field" v-model="sortByFilter">
                <option :selected="true" value="Disconnected">{{ $t('monitor.sessionDisconnected') }}</option>
                <option :selected="true" value="Connected">{{ $t('monitor.sessionConnected') }}</option>
              </select>
            </div>




          </div>
          </div>
            </div>




          <div v-if="isFiltersActive" class="mb-1">
              <WButton secondary outline sm
                @click="resetAllFilters"
              >
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </WButton>
            </div>
        </div>
      </div>
    </div>


    <div class="col-lg-12">
      <div class="cpes" :class="{ 'stat-visible': statVisible }">
        <div class="cpes-rating-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div-->
                  <!--                    class="loader loader-backdrop loader-backdrop-monitor-sessions"-->
                  <!--                    v-if="isCpesSessionsLoading"-->
                  <!--                  ></div>-->
                  <div v-if="isCpesSessionsLoading"><Loader-spinner></Loader-spinner></div>
                  <div class="card-header">
                    <i class="fa fa-bar-chart"></i>
                    {{ $t('general.cpes') }}
                    <div class="float-right" v-if="!statVisible">
                      <date-picker v-model="drPickerDateForRating" :onChange="handleDateChangeForRating.bind(this)" />

                      <download-extract-v2
                        newXlsAutoColumnsWidth
                        :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.monitoringCPEConnectivity'))"
                        :data-for-excel="convertToNewExcelExportModuleFormat(formatToCSV(filterItems(cpesSessionsList)), $t('general.cpes'))"
                        v-if="cpesSessionsList"
                        class="ml-h"
                        :data="formatToCSV(filterItems(cpesSessionsList))"
                        :filename="getFileNameForDownloadFiles($t('downloadFilesNames.monitoringCPEConnectivity'))"
                        force-enable-a2-pdf-page-size
                        :downloads-formats-for-show="{
                          oldCsv: true,
                          newCsv: false,
                          oldXls: false,
                          newXls: true,
                          pdf: true
                        }"
                      />
                    </div>
                  </div>
                  <div class="card-block">
                    <table class="table">
                      <thead>
                        <tr>
                          <th v-if="!statVisible"></th>
                          <th v-if="!statVisible">{{ $t('general.model') }}</th>
                          <th>{{ $t('general.name') }}</th>
                          <th>{{ $t('monitor.sessions') }}</th>
                          <th v-if="!statVisible && areLocationsVisible">{{ $t('general.location') }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(cpe, index) in filterItems(cpesSessionsList)"
                          :data-index="index"
                          :data-id="cpe.id"
                          :key="cpe.id"
                          v-if="cpe.name"
                          class="cpe-row"
                          :class="{ selected: statCpeId === cpe.id }"
                          @click="toggleEdit"
                        >
                          <td v-if="!statVisible">{{ index + 1 }}</td>
                          <td v-if="!statVisible">
                            <span v-if="cpe.model">{{ cpe.model.name }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="cpe.name">{{ cpe.name }}</span>
                            <span v-else class="text-muted">
                              No data
                              <br />
                              <small v-if="!statVisible">(cpe id: {{ cpe.id }} )</small>
                            </span>
                          </td>
                          <td>
                            <span v-if="cpe.sessions">
                              <span class="text-danger" v-if="percentData(fullPeriod, totalOff(cpe.sessions)) > 50">
                                <i class="fa fa-exclamation"></i>
                              </span>
                              <span class>{{ momentnow(fullPeriod - totalOff(cpe.sessions)) }}</span>
                              <small
                                :class="{
                                  'text-success': percentData(fullPeriod, totalOff(cpe.sessions)) < 20,
                                  'text-warning':
                                    percentData(fullPeriod, totalOff(cpe.sessions)) >= 20 &&
                                    percentData(fullPeriod, totalOff(cpe.sessions)) <= 50,
                                  'text-danger': percentData(fullPeriod, totalOff(cpe.sessions)) > 50
                                }"
                                v-if="fullPeriod - totalOff(cpe.sessions)"
                              >
                                ({{ (100 - percentData(fullPeriod, totalOff(cpe.sessions))).toFixed(1) }}%)
                              </small>
                            </span>
                            <span class="text-danger" v-else>{{ $t('sessions.noSessions') }}</span>
                          </td>
                          <td v-if="!statVisible && areLocationsVisible" class="responsive-row">{{ locationName(cpe.base_location) }}</td>
                          <td v-if="!statVisible" class="action-row">
                            <i
                              class="fa fa-wifi fa-lg mr-q text-primary user-action"
                              v-if="cpe.connected && !isDisable"
                              data-action="scan"
                            ></i>
                          </td>
                        </tr>

                        <tr v-if="!filterItems(cpesSessionsList).length">
                          <td colspan="9" class="no-data-row">
                            <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cpes-stat" v-if="statVisible">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <!--                <div class="loader loader-backdrop loader-backdrop-monitor-sessions" v-if="isCpesScannerLoading"></div>-->
                <div v-if="isCpesScannerLoading"><Loader-spinner></Loader-spinner></div>
                <div class="card-header">
                  <img class="img img-responsive cpe-logo" src="/static/img/router.svg" alt />
                  <span v-if="cpeStatCurrent">{{ cpeStatCurrent.name }}</span>
                  <span v-else>{{ cpeSelectedId }}</span>
                  <WButton secondary outline sm
                    customClass="float-right"
                    @click="disableStatMode"
                  >
                    <i class="fa fa-close"></i>
                  </WButton>
                </div>
                <div class="card-block">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="float-right">
                        <date-picker v-model="drPickerDateForRating" :onChange="handleDateChangeForRating.bind(this)" />
                        <download-extract-v2
                          newXlsAutoColumnsWidth
                          :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.monitoringCPEConnectivity')}-${cpeSelectedId}-${$t('monitor.cpeState')}`)"
                          :data-for-excel="convertToNewExcelExportModuleFormat(getSession(cpesSessionsList, cpeSelectedId), $t('monitor.cpesState'))"
                          v-if="getSession(cpesSessionsList, cpeSelectedId).length"
                          class="ml-h"
                          :data="getSession(cpesSessionsList, cpeSelectedId)"
                          :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.monitoringCPEConnectivity')}-${cpeSelectedId}-${$t('monitor.cpeState')}`)"
                          :downloads-formats-for-show="{
                            oldCsv: true,
                            newCsv: false,
                            oldXls: false,
                            newXls: true,
                            pdf: true
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row mt-1">
                      <div class="col-lg-12">
                        <highcharts :options="sessionChart" ref="highcharts"></highcharts>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--              <div-->
              <!--                class="card mt-2"-->
              <!--                v-if="scanResult && scanResult.scanlist && Object.keys(scanResult.scanlist).length"-->
              <!--              >-->
              <!--                <div class="loader loader-backdrop loader-backdrop-monitor-sessions" v-if="isCpesScannerLoading"></div>-->
              <!--                <div class="card-header">-->
              <!--                  <i class="fa fa-wifi"></i>-->
              <!--                  Wi-Fi analyzer-->
              <!--                </div>-->
              <!--                <div-->
              <!--                  class="card-block"-->
              <!--                  v-if="two_ghz_scanner.series.length || five_ghz_scanner.series.length"-->
              <!--                >-->
              <!--                  <div class="row">-->
              <!--                    <div class="col-lg-12">-->
              <!--                      <div class="row mt-1">-->
              <!--                        <div class="col-lg-12" v-if="two_ghz_scanner.series.length">-->
              <!--                          <highcharts :options="two_ghz_scanner" ref="highcharts"></highcharts>-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                      <div class="row mt-1">-->
              <!--                        <div class="col-lg-12" v-if="five_ghz_scanner.series.length">-->
              <!--                          <highcharts :options="five_ghz_scanner" ref="highcharts"></highcharts>-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->

              <!--                <div class="card-block" v-if="scanResult">-->
              <!--                  <div class="wlan-settings-block">-->
              <!--                    <div class="wlan-settings-block__header" @click.prevent="showScanResult">-->
              <!--                      <span class="h6 mb-2 mr-1">Characteristics of WLANs</span>-->
              <!--                      <button class="btn btn-link float-right" v-if="!isScanResultShow">-->
              <!--                        <i class="icon-arrow-left"></i>-->
              <!--                      </button>-->
              <!--                      <button class="btn btn-link float-right" v-if="isScanResultShow">-->
              <!--                        <i class="icon-arrow-down"></i>-->
              <!--                      </button>-->
              <!--                    </div>-->
              <!--                    <transition name="wsettings">-->
              <!--                      <div class v-if="isScanResultShow">-->
              <!--                        <table class="table">-->
              <!--                          <thead>-->
              <!--                            <tr>-->
              <!--                              <th>-->
              <!--                                <input-->
              <!--                                  type="checkbox"-->
              <!--                                  name="checkbox2"-->
              <!--                                  value="option"-->
              <!--                                  @click="checkAllBssids"-->
              <!--                                  id="checkAll"-->
              <!--                                  :checked="checkAllState"-->
              <!--                                />-->
              <!--                              </th>-->
              <!--                              <th>BSSID</th>-->
              <!--                              <th>SSID</th>-->
              <!--                              <th>Signal</th>-->
              <!--                              <th>{{ $t('wlans.security') }}</th>-->
              <!--                              <th>Channel</th>-->
              <!--                              <th>Quality</th>-->
              <!--                            </tr>-->
              <!--                          </thead>-->
              <!--                          <tbody>-->
              <!--                            <tr-->
              <!--                              v-for="(item, index) in scanResult.scanlist"-->
              <!--                              :data-index="index"-->
              <!--                              :key="item.bssid"-->
              <!--                            >-->
              <!--                              <td>-->
              <!--                                <input-->
              <!--                                  type="checkbox"-->
              <!--                                  name="checkbox2"-->
              <!--                                  value="option"-->
              <!--                                  :checked="isSsidChecked(item.bssid)"-->
              <!--                                  @click="toggleBssid(item.bssid)"-->
              <!--                                />-->
              <!--                              </td>-->
              <!--                              <td class="responsive-row">{{ item.bssid }}</td>-->
              <!--                              <td class="responsive-row">{{ item.ssid }}</td>-->
              <!--                              <td class="responsive-row">{{ item.signal }} dBm</td>-->
              <!--                              <td class="responsive-row">{{ item.encryption.description }}</td>-->
              <!--                              <td class="responsive-row">{{ item.channel }}</td>-->
              <!--                              <td class="responsive-row">{{ item.quality }}</td>-->
              <!--                            </tr>-->
              <!--                          </tbody>-->
              <!--                        </table>-->
              <!--                      </div>-->
              <!--                    </transition>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';
import cpeService from '../../services/cpeService';
import statCpeService from '../../services/statCpeService';
import commonService from '../../services/commonService';
import Filters from '../../filters';
import helpers from '../../helpers';
import DatePicker from '../../components/date-picker.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import locationService from '../../services/locationService';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

import { intervalUpdateModeMixin } from '@/mixins';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');

const two_ghz_chanlist = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'];
const five_ghz_chanlist = [
  '36',
  '38',
  '40',
  '42',
  '44',
  '46',
  '52',
  '56',
  '64',
  '100',
  '104',
  '108',
  '112',
  '116',
  '120',
  '124',
  '128',
  '132',
  '136',
  '140',
  '149',
  '154',
  '157',
  '161',
  '165'
];

export default {
  name: 'Sessions',
  components: { DatePicker, SwitchComponent, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors },
  mixins: [intervalUpdateModeMixin],
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      cpesObject: {},
      searchQuery: '',
      isScanResultShow: false,
      selectedCpes: [],
      filteredCpes: [],
      statCpeId: '',
      currentCpeStats: [],
      scanResult: {},
      ssidsToDisplay: [],
      drPickerDateForRating: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      drPickerDateForStat: {
        startDate: '',
        endDate: ''
      },
      filters: {
        query: '',
        interfacesFilter: '',
        connectedFilter: '',
        location: 'All locations',
        wlan: 'All wlans',
        with_childs: false,
      },
      sortByFilter: 'Connected',
      two_ghz_scanner: {
        chart: {
          type: 'area',
          spacingBottom: 30,
          zoomType: 'x'
        },
        title: {
          text: '2.4 GHz'
        },
        subtitle: {
          text: 'Current Frequency Channels (bandwidth)',
          floating: true,
          align: 'right',
          verticalAlign: 'bottom',
          y: 15
        },
        xAxis: {
          categories: two_ghz_chanlist
        },
        yAxis: {
          title: {
            text: 'Signal dBm'
          },
          labels: {
            formatter() {
              return this.value;
            }
          }
        },
        tooltip: {
          formatter() {
            return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}`;
          }
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: true,
                lineWidth: 5,
                opacity: 0.9
              }
            }
          },
          area: {
            fillOpacity: 0.5
          }
        },
        credits: {
          enabled: false
        },
        series: []
      },
      five_ghz_scanner: {
        chart: {
          type: 'area',
          spacingBottom: 30,
          zoomType: 'x'
        },
        title: {
          text: '5 GHz'
        },
        subtitle: {
          text: 'Current Frequency Channels (bandwidth)',
          floating: true,
          align: 'right',
          verticalAlign: 'bottom',
          y: 15
        },
        xAxis: {
          categories: five_ghz_chanlist
        },
        yAxis: {
          title: {
            text: 'Signal dBm'
          },
          labels: {
            formatter() {
              return this.value;
            }
          }
        },
        tooltip: {
          formatter() {
            return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}`;
          }
        },
        plotOptions: {
          area: {
            fillOpacity: 0.5
          }
        },
        credits: {
          enabled: false
        },
        series: []
      },
      sessionChart: {
        chart: {
          type: 'area',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
          text: this.$t('statistics.pan')
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
          text: this.$t('monitor.cpeState'),
          x: -20 // center
        },
        tooltip: {
          pointFormat: '<b>{series.name}</b>'
        },
        xAxis: {
          type: 'datetime',
          min: '',
          max: ''
        },
        yAxis: {
          title: {
            text: ''
          },
          minTickInterval: 1,
          gridLineWidth: 0,
          categories: ['', ''],
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#cccccc'
            }
          ],
          min: 0,
          max: 1
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
            name: this.$t('aps.connected'),
            data: [],
            color: '#81C784'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
            name: this.$t('aps.disconnected'),
            data: [],
            color: '#E57373'
          }
        ],

        step: 'left'
      }
    };
  },
  computed: {
    ...localeMapGetters(['locale']),
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isCpesScannerLoading() {
      return this.$store.state.loadingCpesScanner;
    },
    isFiltersActive() {
      return (
        this.filters.location !== 'All locations' ||
          this.filters.interfacesFilter !== '' ||
           this.filters.connectedFilter !== '' ||
          this.filters.query !== '' ||
          this.filters.wlan !== 'All wlans' || this.sortByFilter !== 'Connected'
      );
    },
    statVisible() {
      return this.$store.state.statCpeId;
    },
    isCpesSessionsLoading() {
      return this.$store.state.loadingCpesSessions;
    },
    isCpesRatingLoading() {
      return this.$store.state.loadingCpesRating;
    },
    checkAllState() {
      if (this.scanResult) {
        return Object.keys(this.scanResult.scanlist).length === this.ssidsToDisplay.length;
      }
    },
    cpesSessionsList() {
      const { cpesSessionsList, cpesList } = this.$store.state;
      const cpesSessionsIds = helpers.combineIdsArrayFromObjectsArray(cpesSessionsList);
      const cpesSessionsListFull = this.$store.state.cpesSessionsList;
      if (cpesList && cpesSessionsIds) {
        cpesList.forEach((cpe) => {
          if (!cpesSessionsIds.includes(cpe.id)) {
            cpesSessionsListFull.push({
              id: cpe.id,
              name: cpe.name,
              base_location: cpe.base_location,
              connected: cpe.connected,
              model: { name: cpe.model.name }
            });
          }
        });
      }
      if (cpesSessionsListFull) {
        cpesSessionsListFull.forEach((cpe) => {
          const findCpe = commonService.cpeObjbyId(cpe.id);
          cpe.offPeriod = this.totalOff(cpe.sessions);
          if (findCpe) {
            cpe.name = findCpe.name;
            cpe.model = findCpe.model;
            cpe.base_location = findCpe.base_location;
            cpe.connected = findCpe.connected;
          }
        });
      }
      return cpesSessionsListFull;
    },
    fullPeriod() {
      const periodStart = parseInt(this.drPickerDateForRating.startDate) / 1000;
      let periodStop = parseInt(this.drPickerDateForRating.endDate) / 1000;
      if (periodStop * 1000 > Date.now()) {
        periodStop = Date.now() / 1000;
      }
      return periodStop - periodStart;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    cpeSelectedId() {
      this.statCpeId = this.$store.state.statCpeId;
      return this.$store.state.statCpeId;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    cpeStatCurrent() {
      const { cpesList } = this.$store.state;
      let cpeObj;
      cpesList.forEach((cpe) => {
        if (cpe.id === this.cpeSelectedId) {
          cpeObj = cpe;
        }
      });
      return cpeObj;
    }
  },
  methods: {
    /**
     * Меняет разнообразные подписи и имена на графике sessionChart, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOnSessionChart() {
      this.sessionChart.subtitle.text = this.$t('statistics.pan');
      this.sessionChart.title.text = this.$t('monitor.cpeState');
      this.sessionChart.series[0].name = this.$t('aps.connected');
      this.sessionChart.series[1].name = this.$t('aps.disconnected');
    },
    getParentLocationIdAndChildLocationsIDsConcatenatedArrayByLocationId(locationId){
      return this.$store.getters.getParentLocationIdAndChildLocationsIDsConcatenatedArrayByLocationId(locationId)
    },
    locationFilter(array, location) {
      if (location === 'All locations') {
        return array;
      }
      if (this.filters.with_childs) {
        const locationsList = this.getParentLocationIdAndChildLocationsIDsConcatenatedArrayByLocationId(location)
        // console.log(array.filter((item) => locationsList.includes(item.base_location)))
        return array.filter((item) => locationsList.includes(item.base_location))
      } else {
        // console.log(array.filter((item) => item.base_location === location))
        return array.filter((item) => item.base_location === location);
      }

    },
    refreshSessions() {
      // console.log(JSON.parse(JSON.stringify(this.filters)));
      this.$nextTick(()=>{
        this.getCpesSessions();
        cpeService.getAllCompactCpesWithLocationFiltering(this);
      })
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    convertToNewExcelExportModuleFormat(inputDataForOldExcelModule, nameForExcelList){
      return helpers.convertFromOldExcelModuleFormatToNewExcelModuleFormat(inputDataForOldExcelModule, nameForExcelList)
    },
        selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'

      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.disableStatModeAndRefreshSessions()


    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.sortByFilter = 'Connected';
    //   this.filters = {
    //     query: '',
    //     interfacesFilter: '',
    //     connectedFilter: '',
    //     location: 'All locations',
    //     wlan: 'All wlans',
    //     with_childs: false,
    //   }
    //   this.disableStatModeAndRefreshSessions()
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.sortByFilter = 'Connected';
      this.filters = {
        query: '',
        interfacesFilter: '',
        connectedFilter: '',
        // location: 'All locations',
        wlan: 'All wlans',
        // with_childs: false,
      }
      if (!this.isSelectedDefaultLocationForRequests){
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.disableStatModeAndRefreshSessions()
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    toggleEdit(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { id } = selectedNode.dataset;
      this.$store.commit('setCpesStatId', id);
      this.refreshCharts();
      this.getScanList();
      this.responseStats();
    },

    // filterItems(items) {
    //   let filteredCpes = items;
    //   filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);
    //   filteredCpes = Filters.locationFilter(filteredCpes, this.filters.location);
    //   this.filteredCpes = filteredCpes;
    //   let sortedFilteredCpes = [];
    //   let first;
    //   let second;
    //   if (this.sortMode === 'desc') {
    //     first = -1;
    //     second = 1;
    //   } else if (this.sortMode === 'asc') {
    //     first = 1;
    //     second = -1;
    //   }
    //   if (this.sortByFilter === 'Disconnected') {
    //     first = -1;
    //     second = 1;
    //     sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
    //       if (curr.offPeriod > prev.offPeriod) {
    //         return first;
    //       }
    //       if (curr.offPeriod < prev.offPeriod) {
    //         return second;
    //       }
    //       return 0;
    //     });
    //   } else if (this.sortByFilter === 'Connected') {
    //     first = 1;
    //     second = -1;
    //     sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
    //       if (curr.offPeriod > prev.offPeriod) {
    //         return first;
    //       }
    //       if (curr.offPeriod < prev.offPeriod) {
    //         return second;
    //       }
    //       return 0;
    //     });
    //   }
    //
    //   return sortedFilteredCpes;
    // },
    // filterItems(items) {
    //   // console.log(items);
    //   let filteredCpes = items;
    //   filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);
    //   filteredCpes = this.locationFilter(filteredCpes, this.filters.location);
    //   this.filteredCpes = filteredCpes;
    //   let sortedFilteredCpes = [];
    //   let first;
    //   let second;
    //   if (this.sortMode === 'desc') {
    //     first = -1;
    //     second = 1;
    //   } else if (this.sortMode === 'asc') {
    //     first = 1;
    //     second = -1;
    //   }
    //   if (this.sortByFilter === 'Disconnected') {
    //     first = -1;
    //     second = 1;
    //     sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
    //       if (curr.offPeriod > prev.offPeriod) {
    //         return first;
    //       }
    //       if (curr.offPeriod < prev.offPeriod) {
    //         return second;
    //       }
    //       return 0;
    //     });
    //   } else if (this.sortByFilter === 'Connected') {
    //     first = 1;
    //     second = -1;
    //     sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
    //       if (curr.offPeriod > prev.offPeriod) {
    //         return first;
    //       }
    //       if (curr.offPeriod < prev.offPeriod) {
    //         return second;
    //       }
    //       return 0;
    //     });
    //   }
    //
    //   return sortedFilteredCpes;
    // },
    filterItems(items) {
      // console.log(items);
      let filteredCpes = items;
      filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);
      //  filteredCpes = this.locationFilter(filteredCpes, this.filters.location);
      this.filteredCpes = filteredCpes;
      let sortedFilteredCpes = [];
      let first;
      let second;
      if (this.sortMode === 'desc') {
        first = -1;
        second = 1;
      } else if (this.sortMode === 'asc') {
        first = 1;
        second = -1;
      }
      if (this.sortByFilter === 'Disconnected') {
        first = -1;
        second = 1;
        sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
          if (curr.offPeriod > prev.offPeriod) {
            return first;
          }
          if (curr.offPeriod < prev.offPeriod) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Connected') {
        first = 1;
        second = -1;
        sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
          if (curr.offPeriod > prev.offPeriod) {
            return first;
          }
          if (curr.offPeriod < prev.offPeriod) {
            return second;
          }
          return 0;
        });
      }

      return sortedFilteredCpes;
    },
    disableStatMode() {
      this.statCpeId = '';
      this.$store.commit('setCpesStatId', null);
    },
    disableStatModeAndRefreshSessions(){
      this.disableStatMode();
      this.refreshSessions();
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    cutName(name) {
      return commonService.cutName(name);
    },
    cpeObj(id) {
      return commonService.cpeObjbyId(id);
    },
    handleDateChangeForRating() {
      this.getCpesSessions(this);
      cpeService.getAllCompactCpesWithLocationFiltering(this);
    },
    handleDateChangeForStat(newDate) {
      this.drPickerDateForStat.startDate = newDate.start.valueOf();
      this.drPickerDateForStat.endDate = newDate.end.valueOf();
      statCpeService.getCpeStat(this);
    },
    refreshCharts() {
      this.sessionChart.series[0].data = [];
      this.sessionChart.series[1].data = [];
      this.two_ghz_scanner.series = [];
      this.five_ghz_scanner.series = [];
    },
    getCpesSessions() {
      statCpeService.getCpesSessions(this);
    },
    checkAllBssids(event) {
      const checkbocState = event.target.checked;
      if (!checkbocState) {
        this.ssidsToDisplay = [];
      } else {
        this.scanResult = { ...this.scanResult };
      }
      this.responseScannerStats();
    },
    isSsidChecked(bssid) {
      return this.ssidsToDisplay.includes(bssid);
    },
    toggleBssid(bssid) {
      if (this.ssidsToDisplay.indexOf(bssid) !== -1) {
        this.ssidsToDisplay.splice(this.ssidsToDisplay.indexOf(bssid), 1);
      } else {
        this.ssidsToDisplay.push(bssid);
      }
      this.responseScannerStats();
    },
    percentData(total, off) {
      const percentData = (100 * off) / total;
      if (percentData < 0.1 && percentData !== 0) {
        return 0.1;
      }
      if (percentData < 0) {
        return 0;
      }
      if (percentData > 100) {
        return 100;
      }
      return percentData.toFixed(1);
    },
    showScanResult() {
      this.isScanResultShow = !this.isScanResultShow;
    },
    responseStats() {
      this.sessionChart.series[0].data = [];
      this.sessionChart.series[1].data = [];

      // Выставляем границы графика по оси X
      const periodStart = this.drPickerDateForRating.startDate / 1000;
      let periodStop = this.drPickerDateForRating.endDate / 1000;
      if (periodStop * 1000 > Date.now()) {
        periodStop = Date.now() / 1000;
      }
      const minXAxis = new Date(periodStart * 1000);
      const maxXAxis = new Date(periodStop * 1000);
      this.sessionChart.xAxis.min = Date.UTC(
        minXAxis.getFullYear(),
        minXAxis.getMonth(),
        minXAxis.getDate(),
        minXAxis.getHours(),
        minXAxis.getMinutes(),
        minXAxis.getSeconds()
      );
      this.sessionChart.xAxis.max = Date.UTC(
        maxXAxis.getFullYear(),
        maxXAxis.getMonth(),
        maxXAxis.getDate(),
        maxXAxis.getHours(),
        maxXAxis.getMinutes(),
        maxXAxis.getSeconds()
      );

      let cpeSession = [];
      const selectedCpe = this.$store.state.statCpeId;
      const sessionsList = this.$store.state.cpesSessionsList;
      sessionsList.forEach((session) => {
        if (selectedCpe === session.id) {
          cpeSession = session;
        }
      });

      let sortedCpeSession;

      if (cpeSession.sessions) {
        sortedCpeSession = cpeSession.sessions.sort((curr, prev) => {
          if (curr.start > prev.start) {
            return 1;
          }
          if (curr.start < prev.start) {
            return -1;
          }
          return 0;
        });
      } else {
        return;
      }

      // Строит график выключенного состояния CPE
      sortedCpeSession.forEach((item, i) => {
        let start = new Date(item.start * 1000);
        let stop = new Date(item.stop * 1000);
        if (item.start < periodStart && (item.stop === 0 || item.stop > periodStop)) {
          start = periodStart;
          stop = periodStop;
          start = new Date(start * 1000);
          stop = new Date(stop * 1000);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours(),
              start.getMinutes(),
              start.getSeconds()
            ),
            1
          ]);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              stop.getFullYear(),
              stop.getMonth(),
              stop.getDate(),
              stop.getHours(),
              stop.getMinutes(),
              stop.getSeconds()
            ),
            1
          ]);
        } else if (item.start > periodStart && (item.stop > periodStop || item.stop === 0)) {
          stop = periodStop;
          stop = new Date(stop * 1000);
          let offStart = periodStart;
          offStart = new Date(offStart * 1000);
          if (sortedCpeSession[i - 1]) {
            const prevStop = new Date(sortedCpeSession[i - 1].stop * 1000);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                prevStop.getFullYear(),
                prevStop.getMonth(),
                prevStop.getDate(),
                prevStop.getHours(),
                prevStop.getMinutes(),
                prevStop.getSeconds()
              ),
              null
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                prevStop.getFullYear(),
                prevStop.getMonth(),
                prevStop.getDate(),
                prevStop.getHours(),
                prevStop.getMinutes(),
                prevStop.getSeconds()
              ),
              0.3
            ]);
          } else {
            this.sessionChart.series[1].data.push([
              Date.UTC(
                offStart.getFullYear(),
                offStart.getMonth(),
                offStart.getDate(),
                offStart.getHours(),
                offStart.getMinutes(),
                offStart.getSeconds()
              ),
              0.3
            ]);
          }
          this.sessionChart.series[1].data.push([
            Date.UTC(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours(),
              start.getMinutes(),
              start.getSeconds()
            ),
            0.3
          ]);
          this.sessionChart.series[1].data.push([
            Date.UTC(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours(),
              start.getMinutes(),
              start.getSeconds()
            ),
            null
          ]);

          this.sessionChart.series[0].data.push([
            Date.UTC(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours(),
              start.getMinutes(),
              start.getSeconds()
            ),
            null
          ]);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours(),
              start.getMinutes(),
              start.getSeconds()
            ),
            1
          ]);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              stop.getFullYear(),
              stop.getMonth(),
              stop.getDate(),
              stop.getHours(),
              stop.getMinutes(),
              stop.getSeconds()
            ),
            1
          ]);
        } else if (item.start < periodStart && item.stop < periodStop) {
          start = periodStart;
          start = new Date(start * 1000);

          let offStop = periodStop;
          offStop = new Date(offStop * 1000);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours(),
              start.getMinutes(),
              start.getSeconds()
            ),
            1
          ]);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              stop.getFullYear(),
              stop.getMonth(),
              stop.getDate(),
              stop.getHours(),
              stop.getMinutes(),
              stop.getSeconds()
            ),
            1
          ]);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              stop.getFullYear(),
              stop.getMonth(),
              stop.getDate(),
              stop.getHours(),
              stop.getMinutes(),
              stop.getSeconds()
            ),
            null
          ]);

          this.sessionChart.series[1].data.push([
            Date.UTC(
              stop.getFullYear(),
              stop.getMonth(),
              stop.getDate(),
              stop.getHours(),
              stop.getMinutes(),
              stop.getSeconds()
            ),
            null
          ]);
          this.sessionChart.series[1].data.push([
            Date.UTC(
              stop.getFullYear(),
              stop.getMonth(),
              stop.getDate(),
              stop.getHours(),
              stop.getMinutes(),
              stop.getSeconds()
            ),
            0.3
          ]);
          if (sortedCpeSession[i + 1]) {
            const nextStart = new Date(sortedCpeSession[i + 1].start * 1000);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                nextStart.getFullYear(),
                nextStart.getMonth(),
                nextStart.getDate(),
                nextStart.getHours(),
                nextStart.getMinutes(),
                nextStart.getSeconds()
              ),
              0.3
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                nextStart.getFullYear(),
                nextStart.getMonth(),
                nextStart.getDate(),
                nextStart.getHours(),
                nextStart.getMinutes(),
                nextStart.getSeconds()
              ),
              null
            ]);
          } else {
            this.sessionChart.series[1].data.push([
              Date.UTC(
                offStop.getFullYear(),
                offStop.getMonth(),
                offStop.getDate(),
                offStop.getHours(),
                offStop.getMinutes(),
                offStop.getSeconds()
              ),
              0.3
            ]);
          }
        } else if (item.start > periodStart && item.stop < periodStop) {
          if (sortedCpeSession[i - 1]) {
            const prevStop = new Date(sortedCpeSession[i - 1].stop * 1000);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                prevStop.getFullYear(),
                prevStop.getMonth(),
                prevStop.getDate(),
                prevStop.getHours(),
                prevStop.getMinutes(),
                prevStop.getSeconds()
              ),
              null
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                prevStop.getFullYear(),
                prevStop.getMonth(),
                prevStop.getDate(),
                prevStop.getHours(),
                prevStop.getMinutes(),
                prevStop.getSeconds()
              ),
              0.3
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                start.getFullYear(),
                start.getMonth(),
                start.getDate(),
                start.getHours(),
                start.getMinutes(),
                start.getSeconds()
              ),
              0.3
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                start.getFullYear(),
                start.getMonth(),
                start.getDate(),
                start.getHours(),
                start.getMinutes(),
                start.getSeconds()
              ),
              null
            ]);
          } else {
            let offStart = periodStart;
            offStart = new Date(offStart * 1000);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                offStart.getFullYear(),
                offStart.getMonth(),
                offStart.getDate(),
                offStart.getHours(),
                offStart.getMinutes(),
                offStart.getSeconds()
              ),
              0.3
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                start.getFullYear(),
                start.getMonth(),
                start.getDate(),
                start.getHours(),
                start.getMinutes(),
                start.getSeconds()
              ),
              0.3
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                start.getFullYear(),
                start.getMonth(),
                start.getDate(),
                start.getHours(),
                start.getMinutes(),
                start.getSeconds()
              ),
              null
            ]);
          }

          if (sortedCpeSession[i + 1]) {
            const nextStart = new Date(sortedCpeSession[i + 1].start * 1000);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                stop.getFullYear(),
                stop.getMonth(),
                stop.getDate(),
                stop.getHours(),
                stop.getMinutes(),
                stop.getSeconds()
              ),
              null
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                stop.getFullYear(),
                stop.getMonth(),
                stop.getDate(),
                stop.getHours(),
                stop.getMinutes(),
                stop.getSeconds()
              ),
              0.3
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                nextStart.getFullYear(),
                nextStart.getMonth(),
                nextStart.getDate(),
                nextStart.getHours(),
                nextStart.getMinutes(),
                nextStart.getSeconds()
              ),
              0.3
            ]);
          } else {
            let offStop = periodStop;
            offStop = new Date(offStop * 1000);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                stop.getFullYear(),
                stop.getMonth(),
                stop.getDate(),
                stop.getHours(),
                stop.getMinutes(),
                stop.getSeconds()
              ),
              null
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                stop.getFullYear(),
                stop.getMonth(),
                stop.getDate(),
                stop.getHours(),
                stop.getMinutes(),
                stop.getSeconds()
              ),
              0.3
            ]);
            this.sessionChart.series[1].data.push([
              Date.UTC(
                offStop.getFullYear(),
                offStop.getMonth(),
                offStop.getDate(),
                offStop.getHours(),
                offStop.getMinutes(),
                offStop.getSeconds()
              ),
              0.3
            ]);
          }

          this.sessionChart.series[0].data.push([
            Date.UTC(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours(),
              start.getMinutes(),
              start.getSeconds()
            ),
            null
          ]);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              start.getFullYear(),
              start.getMonth(),
              start.getDate(),
              start.getHours(),
              start.getMinutes(),
              start.getSeconds()
            ),
            1
          ]);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              stop.getFullYear(),
              stop.getMonth(),
              stop.getDate(),
              stop.getHours(),
              stop.getMinutes(),
              stop.getSeconds()
            ),
            1
          ]);
          this.sessionChart.series[0].data.push([
            Date.UTC(
              stop.getFullYear(),
              stop.getMonth(),
              stop.getDate(),
              stop.getHours(),
              stop.getMinutes(),
              stop.getSeconds()
            ),
            null
          ]);
        }
      });
    },
    responseScannerStats() {
      if (!this.scanResult && !this.scanResult.scanlist) {
        return;
      }
      const { scanlist } = this.scanResult;
      for (const ap in scanlist) {
        const apData = scanlist[ap];
        if (this.ssidsToDisplay.includes(apData.bssid)) {
          if (two_ghz_chanlist.includes(apData.channel.toString())) {
            const emptyChanlist = two_ghz_chanlist.map((channel) => null);
            const channelIndex = two_ghz_chanlist.indexOf(apData.channel.toString());
            emptyChanlist[channelIndex] = apData.quality;
            if (channelIndex + 1 < two_ghz_chanlist.length - 1 || channelIndex === 0) {
              emptyChanlist[channelIndex + 1] = apData.quality;
            } else {
              emptyChanlist[channelIndex - 1] = apData.quality;
            }
            this.two_ghz_scanner.series.push({
              name: apData.ssid || apData.bssid,
              data: emptyChanlist
            });
          } else {
            const emptyChanlist = five_ghz_chanlist.map((channel) => null);
            const channelIndex = five_ghz_chanlist.indexOf(apData.channel.toString());
            emptyChanlist[channelIndex] = apData.quality;
            if (channelIndex + 1 < five_ghz_chanlist.length - 1 || channelIndex === 0) {
              emptyChanlist[channelIndex + 1] = apData.quality;
            } else {
              emptyChanlist[channelIndex - 1] = apData.quality;
            }
            this.five_ghz_scanner.series.push({
              name: apData.ssid || apData.bssid,
              data: emptyChanlist
            });
          }
        }
      }
    },
    getScanList() {
      this.scanResult.scanlist = {};
      const selectedCpe = this.$store.state.statCpeId;
      let count = 2;

      this.$store.commit('toggleLoadingCpesScanner');
      Vue.axios
        .post(`${API_URL || ''}/api/cpes/scan/last`, { action: 'R', ids: [selectedCpe], q: { radio: 'radio0' } })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              count--;
              if (response.data.data.itemslist[0]) {
                this.scanResult.cpe = JSON.parse(JSON.stringify(response.data.data.itemslist[0].cpe));
                this.scanResult.timestamp = JSON.parse(JSON.stringify(response.data.data.itemslist[0].timestamp));
                Object.assign(
                  this.scanResult.scanlist,
                  JSON.parse(JSON.stringify(response.data.data.itemslist[0].scanlist))
                );
                if (count === 0) {
                  this.ssidsToDisplay = Object.keys(this.scanResult.scanlist);
                  this.responseScannerStats();
                }
              }
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
            }
            this.$store.commit('toggleLoadingCpesScanner');
          },
          (err) => {
            VueNotifications.error({ message: err });
          }
        );

      this.$store.commit('toggleLoadingCpesScanner');
      Vue.axios
        .post(`${API_URL || ''}/api/cpes/scan/last`, { action: 'R', ids: [selectedCpe], q: { radio: 'radio1' } })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              count--;
              if (response.data.data.itemslist[0]) {
                Object.assign(
                  this.scanResult.scanlist,
                  JSON.parse(JSON.stringify(response.data.data.itemslist[0].scanlist))
                );
                if (count === 0) {
                  this.ssidsToDisplay = Object.keys(this.scanResult.scanlist);
                  this.responseScannerStats();
                }
              }
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
            }
            this.$store.commit('toggleLoadingCpesScanner');
          },
          (err) => {
            VueNotifications.error({ message: err });
          }
        );
    },
    wlanObj(id) {
      return commonService.wlanObjbyId(id);
    },
    totalOff(sessions) {
      const cpesSessions = sessions;
      const periodStart = this.drPickerDateForRating.startDate / 1000;
      let periodStop = this.drPickerDateForRating.endDate / 1000;
      if (periodStop * 1000 > Date.now()) {
        periodStop = Date.now() / 1000;
      }
      const period = periodStop - periodStart;
      if (!sessions) {
        return period;
      }
      let totalOff = period;
      cpesSessions.forEach((session) => {
        if (session.start < periodStart && (session.stop > periodStop || session.stop === 0)) {
          totalOff = 0;
        } else if (session.start > periodStart && (session.stop > periodStop || session.stop === 0)) {
          const onPeriod = periodStop - session.start;
          totalOff -= onPeriod;
        } else if (session.start < periodStart && session.stop < periodStop) {
          const onPeriod = session.stop - periodStart;
          totalOff -= onPeriod;
        } else if (session.start > periodStart && session.stop < periodStop) {
          const onPeriod = session.stop - session.start;
          totalOff -= onPeriod;
        }
      });

      return totalOff;
    },
    // formatToCSV(sessions) {
    //   if (!sessions) {
    //     return [];
    //   }
    //
    //   let csvSessions = JSON.parse(JSON.stringify(sessions));
    //
    //   csvSessions = csvSessions.map((cpe) => {
    //     if (cpe.model) {
    //       cpe.model = cpe.model.name || cpe.model;
    //     }
    //     return cpe;
    //   });
    //   return csvSessions;
    // },
    // formatToCSV(sessions) {
    //   if (!sessions) {
    //     return [];
    //   }
    //
    //   let csvSessions = JSON.parse(JSON.stringify(sessions));
    //
    //   csvSessions = csvSessions.map((cpe) => {
    //     if (cpe.model) {
    //       cpe.model = cpe.model.name || '';
    //     } else {
    //       cpe.model = '';
    //     }
    //     if (cpe.sessions) {
    //       if (cpe.sessions.length > 0) {
    //         cpe.sessions = cpe.sessions.length;
    //       } else {
    //         cpe.sessions = 0;
    //       }
    //     } else {
    //       cpe.sessions = 0;
    //     }
    //     // дальше выстраиваем в определнном порядке, без этого в pdf данные перемешиваются между колонками
    //     const cpeKeys = ['id', 'sessions', 'offPeriod', 'name', 'model', 'base_location', 'connected'];
    //     const cpeWithSortedKeys = {};
    //     for (const key of cpeKeys) {
    //       cpeWithSortedKeys[key] = cpe[key];
    //     }
    //     return cpeWithSortedKeys;
    //   });
    //   return csvSessions;
    // },
    formatToCSV(sessions) {
      if (!sessions) {
        return [];
      }

      let csvSessions = JSON.parse(JSON.stringify(sessions));
      csvSessions = csvSessions.filter (cpe => cpe.name !== '');
      csvSessions = csvSessions.map((cpe) => {
        if (cpe.model) {
          cpe.model = cpe.model.name || '';
        } else {
          cpe.model = '';
        }
        if (cpe.sessions) {
          if (cpe.sessions.length > 0) {
            cpe.sessions = cpe.sessions.length;
          } else {
            cpe.sessions = 0;
          }
        } else {
          cpe.sessions = 0;
        }
        if (cpe.connected) {
          cpe.connected = this.$t('general.connectedShort');
        } else {
          cpe.connected = this.$t('general.disconnectedShort');
        }
        if (cpe.base_location) {
          cpe.base_location = this.locationName(cpe.base_location);
        } else {
          cpe.base_location = '';
        }
        const i18nCaptionsForColumnsForExport = {
          id: 'ID',
          sessions: 'sessions',
          offPeriod: `${this.$t('general.disabled').slice(0,1).toUpperCase()}${this.$t('general.disabled').slice(1)} ${this.$t('general.secShort')}.`,
          name: this.$t('general.name'),
          model: this.$t('general.model'),
          base_location: this.$t('general.location'),
          connected: this.$t('general.connectedDisconnectedShort')
        };
        // дальше выстраиваем в определнном порядке, без этого в pdf данные перемешиваются между колонками
        // const cpeKeys = ['id', 'sessions', 'offPeriod', 'name', 'model', 'base_location', 'connected'];
       // const cpeKeys = ['id', 'offPeriod', 'name', 'model', 'base_location', 'connected'];
        let cpeKeys;
        if (this.areLocationsVisible) {
          cpeKeys = ['id', 'offPeriod', 'name', 'model', 'base_location', 'connected'];
        } else {
          cpeKeys = ['id', 'offPeriod', 'name', 'model', 'connected'];
        }

        const cpeWithSortedKeys = {};
        for (const key of cpeKeys) {
          cpeWithSortedKeys[i18nCaptionsForColumnsForExport[key]] = cpe[key];
        }
        return cpeWithSortedKeys;
      });
      return csvSessions;
    },

    // getSession(sessions, id) {
    //   if (!sessions) {
    //     return [];
    //   }
    //
    //   let cpeSessions;
    //   // console.log('sessions:', sessions);
    //   // console.log('id:', id);
    //   sessions.forEach((session) => {
    //     if (session.id === id) {
    //       cpeSessions = session.sessions || [];
    //     }
    //   });
    //
    //   return cpeSessions;
    // },
    getSession(sessions, id) {
      if (!sessions) {
        return [];
      }

      let cpeSessions;
      // console.log('sessions:', sessions);
      // console.log('id:', id);
      sessions.forEach((session) => {
        if (session.id === id) {
          const cpeSessionsTmp = session.sessions || [];
          cpeSessions = JSON.parse(JSON.stringify(cpeSessionsTmp))
        }
      });
      cpeSessions.forEach((session)=>{
        // console.log(session)
        // session[this.$t('general.start')] = session.start.toString();
        // session[this.$t('general.stop')] = session.stop.toString();
        // console.log(moment(session.start * 1000).format('DD.MM.YYYY, H:mm:ss'))
        session[this.$t('general.start')] = moment(session.start * 1000).format('DD.MM.YYYY, H:mm:ss');
        session[this.$t('general.stop')] = session.stop.toString();
        // session[this.$t('general.stop')] = moment(session.stop * 1000).format('DD.MM.YYYY, H:mm:ss');
        delete session.start;
        delete session.stop
      });
      // console.log(cpeSessions)
      return cpeSessions;
    },
    momentnow(value) {
      if (value) {
        const day = this.$t('general.day');
        const min = this.$t('general.min');
        const h = this.$t('general.hour');
        const sec = this.$t('general.sec');

        return moment.duration(value, 'seconds').format(`D [${day}] h [${h}], mm [${min}], ss [${sec}]`);
      }
    }
  },
  watch: {
    locale() {
      this.changeCaptionsWithI18nOnSessionChart();
    },
    scanResult() {
      if (this.scanResult) {
        this.ssidsToDisplay = Object.keys(this.scanResult.scanlist);
        this.responseScannerStats();
      }
    }
  },
  created() {
    this.$store.commit('setCpesList', false);

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering
      this.filters.with_childs = withChildsForFiltering;
    }

    this.getCpesSessions();
    // cpeService.getAllCompactCpes(this);
    cpeService.getAllCompactCpesWithLocationFiltering(this);
    // Get wlans for multiselect if empty in store
    this.$store.commit('setCpesEditIndex', null);
    this.$store.commit('setCpesEditIndex', null);
    this.$store.commit('setCpesStatId', null);
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      cpeService.getAllCompactCpesWithLocationFiltering(this);
      this.getCpesSessions();
      if (this.statCpeId) {
        this.getScanList();
      }
    });
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },

    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  }
};
</script>

<style lang="scss">
                          .input-group-for-location-select-in-monitoringSessions--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-monitoringSessions--filters .select__list-container {
    width: 400px;
  }



.cpes {
  display: flex;
}

.cpes-rating-list {
  width: 100%;
}

.cpes-stat {
  display: inline-block;
  width: 0;
  word-wrap: break-word;
}

.stat-visible .cpes-rating-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 25%;
}

.stat-visible .cpes-stat {
  transition: width 0.2s;
  width: 75%;
}

.cpe-row {
  transition: background 0.15s;
  cursor: pointer;
}

.cpe-row.selected {
  background: #d9e3ec;
}

.cpe-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.cpe-row--sorted {
  background: rgba(236, 236, 236, 0.59);
}

.btn {
  cursor: pointer;
}

.stat-visible .progress {
  max-width: 130px;
}

.badge-status__breakdown {
  width: 5px !important;
  height: 5px !important;
  border-radius: 50% !important;
  margin-right: 0 !important;
  padding: 0;
}

.sessions {
  display: flex;
  justify-content: space-between;

  margin-bottom: 0.5rem;
}

div[data-popover='webaccess'] {
  background: #444;
  color: #f9f9f9;
  font-size: 12px;
  line-height: 1.5;
  margin: 3px;
  text-align: center;
  padding: 7px;
  height: 30px;
  width: 100%;
}

div[data-popover='analyzer'] {
  background: #444;
  color: #f9f9f9;
  font-size: 12px;
  line-height: 1.5;
  margin: 3px;
  text-align: center;
  padding: 7px;
  height: 30px;
  width: 100%;
}

.loader-backdrop-monitor-sessions:after {
  position: absolute;
  top: 30px;
}
</style>
